<template>
  <div
    v-if="!formError"
    ref="form"
    class="cc-form"
    :class="{ 'no-padding-bottom': isTransactionTemplate }"
  >
    <div class="cc-form-padding">
      <template v-if="isTransactionTemplate">
        <div class="template-form-header"></div>
      </template>
      <template v-else>
        <div v-if="form.useTitle || form.title || countOptions.status" class="cc-form-header">
          <div v-if="form.useTitle || form.title" class="cc-form-title">
            {{ form.title || '表单内容' }}
          </div>
          <template v-if="countOptions.status">
            <CountDefault v-if="!countOptions.style" :count-options="countOptions" />
            <CountFire
              v-else-if="countOptions.style === countStyles.FIRE"
              :count-options="countOptions"
            />
            <CountSpeaker
              v-else-if="countOptions.style === countStyles.SPEAKER"
              :count-options="countOptions"
            />
          </template>
        </div>
      </template>
      <FormItem
        v-for="item in formItems"
        :key="item.id"
        :class="{ required: !item.is_allow_empty, [item.type]: true }"
        :error="context === 'editor' ? '' : item.error"
        :label="item.label"
        :name="item.name"
        :type="item.type"
      >
        <AddressComp
          v-if="item.type === types.ADDRESS"
          v-model="item.value"
          :data="item"
          :area-list="areaList"
          @change="validateField(item)"
          @focus="onFocusReport(item)"
        />
        <Telephone
          v-if="item.type === types.TELEPHONE"
          ref="telephone"
          v-model="item.value"
          :b-phone-info="bPhoneInfo"
          :data="item"
          :history="history"
          :placeholder="item.placeholder"
          :tel-comp-info="telCompInfo"
          :theme-color="themeColor"
          :is-template-pre="isTemplatePre"
          @change="validateField(item)"
          @focus="onFocusReport(item)"
          @on-auto-fill-name="autoFillName"
          @on-auto-fill-phone="autoFillPhone"
          @submit="submit"
        />
        <ItemDropdown
          v-if="[types.DROPDOWN, types.LBS].includes(item.type)"
          v-model="item.value"
          :data="item"
          :history="history"
          :form-id="form.id"
          :has-location="item.type === types.LBS"
          :theme-color="themeColor"
          :placeholder="item.placeholder"
          @change="validateField(item)"
          @focus="onFocusReport(item)"
        />
        <LbsReal
          v-if="[types.CUSTOMIZE_LBS_DROP_DOWN].includes(item.type)"
          v-model="item.value"
          :data="item"
          :history="history"
          :form-id="form.id"
          :has-location="item.type === types.LBS"
          :theme-color="themeColor"
          @change="validateField(item)"
          @focus="onFocusReport(item)"
        />
        <FormInput
          v-if="[types.INPUT, types.IDCARD].includes(item.type)"
          v-model="item.value"
          :maxlength="item.sub_type === 1 ? 20 : 200"
          :name="item.id"
          :placeholder="item.placeholder"
          :type="item.type"
          @change="validateField(item)"
          @focus="onFocusReport(item)"
        />
        <van-checkbox-group
          v-if="item.type === types.CHECKBOX"
          v-model="item.value"
          :checked-color="themeColor"
          :class="{ 'wrap-outer': item.show_rule > 0 }"
          @change="validateField(item)"
        >
          <van-checkbox
            v-for="(_item, index) in item.options"
            :key="index"
            :class="{ 'wrap-inner': item.show_rule > 0 }"
            :name="_item.text"
            shape="square"
            @click="onFocusReport(item)"
          >
            {{ _item.text }}
          </van-checkbox>
        </van-checkbox-group>
        <van-radio-group
          v-if="item.type === types.RADIO"
          v-model="item.value"
          :checked-color="themeColor"
          :class="{ 'wrap-outer': item.show_rule > 0 }"
          @change="validateField(item)"
        >
          <van-radio
            v-for="(_item, index) in item.options"
            :key="index"
            :class="{ 'wrap-inner': item.show_rule > 0 }"
            :name="_item.text"
            @click="onFocusReport(item)"
          >
            {{ _item.text }}
          </van-radio>
        </van-radio-group>
      </FormItem>
      <div
        v-if="!isTransactionTemplate"
        :id="`gaoneng-form-submit-${(form && form.id) || ''}`"
        class="form-item form-item-btn report-wrap-module"
      >
        <div
          v-if="
            telCompInfo &&
            telCompInfo.show_auto_fill &&
            (telCompInfo.is_submit_validate ||
              (!telCompInfo.is_submit_validate && !telCompInfo.useLocalPhone))
          "
          class="tel-help"
        >
          <div class="tel-help-checkbox">
            <van-checkbox
              v-model="telCompInfo.autoFill"
              :checked-color="themeColor"
              shape="square"
              size="small"
            />
          </div>
          <div class="tel-help-msg">
            下次自动填写，阅读并同意<a
              class="tel-link"
              target="_blank"
              @click="$refs.modalAuth.open"
              >《授权书》</a
            >
          </div>
        </div>
        <van-button
          v-if="!isTransactionTemplate"
          v-show="formButton.label"
          :class="buttonClass"
          :style="buttonStyle"
          class="submit-btn"
          tag="a"
          type="info"
          @click="confirm"
          >{{ formButton.label }}
        </van-button>
      </div>
      <ScrollBar
        v-if="!!recentSubmit.status && recentSubmit.style === 'scrollBar' && scrollShow"
        :desc="recentSubmit.desc"
        :form-id="form.id"
        :list="formData.form_data"
        :style-color="recentSubmit.styleColor"
        :theme-color="themeColor"
      />
      <ScrollWall
        v-else-if="!!recentSubmit.status && recentSubmit.style === 'scrollWall' && scrollShow"
        :desc="recentSubmit.desc"
        :form-id="form.id"
        :list="formData.form_data"
        :style-color="recentSubmit.styleColor"
        :theme-color="themeColor"
      />
      <AgreementAuth ref="modalAuth"></AgreementAuth>
      <AgreementConfirm ref="modalConfirm" @submit="$emit('submit')"></AgreementConfirm>
      <AuthConfirm
        ref="auth"
        auth-type="telephone"
        @confirm="onAuthConfirm"
        @open="onAuthOpen"
        @refuse="onAuthRefuse"
        @read="onAuthRead"
        @click-overlay="onAuthClickOverlay"
      ></AuthConfirm>
    </div>
    <template v-if="isTransactionTemplate">
      <div class="template-m">
        <div
          :id="`gaoneng-form-submit-${(form && form.id) || ''}`"
          class="form-item form-item-btn report-wrap-module padding-no"
        >
          <div
            v-if="
              telCompInfo &&
              telCompInfo.show_auto_fill &&
              (telCompInfo.is_submit_validate ||
                (!telCompInfo.is_submit_validate && !telCompInfo.useLocalPhone))
            "
            class="tel-help"
          >
            <div class="tel-help-checkbox">
              <van-checkbox
                v-model="telCompInfo.autoFill"
                :checked-color="themeColor"
                shape="square"
                size="small"
              />
            </div>
            <div class="tel-help-msg">
              下次自动填写，阅读并同意<a
                class="tel-link"
                target="_blank"
                @click="$refs.modalAuth.open"
                >《授权书》</a
              >
            </div>
          </div>
        </div>
        <div class="text">
          提交即视为您已阅读并同意<span class="agreement" @click="$refs.modal.open()"
            >《个人信息保护声明》</span
          >
        </div>
        <AgreementPrivacy ref="modal"></AgreementPrivacy>
      </div>
    </template>
  </div>
  <div v-else class="cc-form">
    <FormItem class="form-item-failed" style="display: none" />
    <van-empty description="表单获取失败" />
  </div>
</template>
<script>
import qs from 'qs'
import {
  formatFormItem,
  getCardAge,
  getOffsetTop,
  IdentityCodeValid,
  serializeBPhone,
  serializeForm,
  serializePhone,
  serializePhoneExt
} from './utils'
import FormItem from './FormItem'
import AddressComp from './Address'
import Telephone from './Telephone'
import FormInput from './NormalInput'
import ScrollWall from './scroll/scrollWall/index'
import ScrollBar from './scroll/ScrollBar'
import CountDefault from './count/CountDefault'
import CountFire from './count/CountFire'
import CountSpeaker from './count/CountSpeaker'
import AgreementConfirm from './agreements/AgreementConfirm'
import AgreementAuth from './agreements/AgreementLocation'
import AgreementPrivacy from './agreements/AgreementPrivacy'
import AuthConfirm from './agreements/LocationAuthConfirm'
import ItemDropdown from './ItemDropdown'
import {
  MINI_experiment_add_form_button,
  MINI_form_loading_success,
  MINI_lbs_dropdown_click,
  MINI_list_address,
  MINI_list_radio,
  MINI_list_checkbox,
  MINI_list_dropdown,
  MINI_list_fail,
  MINI_list_form_submit_click,
  MINI_list_idcard,
  MINI_list_input,
  MINI_list_phone,
  MINI_list_pv,
  MINI_list_success,
  MINI_login_phone_modal_yes,
  MINI_login_phone_modal_no,
  MINI_login_phone_modal_show,
  MINI_login_phone_modal_overlay,
  MINI_page_rendered,
  MINI_login_phone_success,
  MINI_login_phone_fail,
  MINI_list_input_cvr,
  MINI_list_idcard_cvr,
  MINI_list_checkbox_cvr,
  MINI_list_radio_cvr,
  MINI_list_address_cvr,
  MINI_list_phone_cvr,
  MINI_list_dropdown_cvr,
  MINI_lbs_dropdown_click_cvr,
  MINI_get_form_history,
  MINI_no_get_form_history
} from '../../constants/reportEvents'
import {
  allowHistoryStatusEnum,
  types,
  bPhoneExpEnum,
  phoneChannelEnum,
  allowLoginInfoToastEnum
} from './constants'
import { basePersonalURL, baseOpenURL } from '../../../config'
import { getFormButtonExpInfo, isIOS } from '@/canvas/utils'
import LbsReal from './lbs/LbsReal'
import {
  WARN_page_form_phone_fail,
  WARN_page_form_submit_fail,
  WARN_page_get_form_data_fail
} from '@/canvas/constants/warnEvents'
import { inBiliApp } from '@bilibili/js-bridge'

export default {
  name: 'CcForm',
  components: {
    ItemDropdown,
    AgreementConfirm,
    AgreementAuth,
    CountSpeaker,
    CountFire,
    CountDefault,
    ScrollWall,
    ScrollBar,
    FormItem,
    AddressComp,
    Telephone,
    FormInput,
    AuthConfirm,
    AgreementPrivacy,
    LbsReal
  },
  inject: ['pageId', 'pageQuery', 'compName', 'compId'],
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: 'default'
    },
    theme: {
      type: String,
      default: 'light'
    },
    context: {
      type: String,
      default: ''
    },
    container: {
      type: HTMLDivElement,
      default: null
    },
    action: {
      type: Object,
      default: null
    },
    isFloat: {
      type: Boolean,
      default: false
    },
    formInfo: {
      type: Object,
      default: null
    },
    // 浮层表单时，当前浮层的显示状态，用于刷新滚动组件
    show: {
      type: Boolean,
      default: false
    },
    // 是否为交易业务定制的模版
    isTransactionTemplate: {
      type: Boolean,
      default: false
    },
    isTemplatePre: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const pageQuery = (window.location.search || '').substr(1)
    const query = qs.parse(pageQuery) || {}
    const formId = query.formId
    const referCompId = query.compId
    const reportInfo = {
      item_name: this.compName || 'page-detail-form',
      item_id: this.compId || referCompId,
      item_from: formId ? 1 : 0,
      form_id: formId || this.data?.form?.id,
      ...(formId ? { item_from_id: referCompId } : {}),
      is_float: +this.isFloat
    }
    return {
      isFromRefer: !!formId,
      reportInfo,
      formError: false,
      formData: {},
      formItems: [],
      formButton: {},

      countStyles: {
        DEFAULT: 0,
        FIRE: 1,
        SPEAKER: 2
      },
      formDataCount: 0,
      formErrors: {},
      hasSubmitted: false,
      submitLock: false,

      // 电话组件
      telCompInfo: {
        autoFill: false,
        useLocalPhone: false
      },

      rootRef: null,
      areaList: [],
      // 用户历史信息
      history: {
        isEmpty: true
      },
      types,
      hasTelephone: false,
      loaded: false,
      bPhoneInfo: {},
      // 经营助手名单禁用信息
      accDisableData: {}
    }
  },
  computed: {
    form() {
      return this.data?.form || {}
    },
    themeColor() {
      return this.data?.style?.themeColor || '#0A8AFA'
    },
    formColor() {
      return this.data?.style?.color || {}
    },
    buttonStyle() {
      const { borderRadius, borderWidth, fontSize, ...style } = this?.data?.buttonStyle || {}

      return {
        ...style,
        borderRadius: `${borderRadius}px`,
        borderWidth: `${borderWidth}px`,
        fontSize: `${fontSize}px`
      }
    },
    buttonClass() {
      const { animation } = this.data?.buttonStyle || {}
      return `animation-${animation}`
    },
    className() {
      const { inputStyle = 1 } = this.data?.style || {}

      return `form-input-style-${inputStyle}`
    },
    recentSubmit() {
      return this.data?.advanceSetting?.recentSubmit || {}
    },
    countOptions() {
      const options = this.data?.advanceSetting?.count || {}
      const { middle } = options
      const formCount = this.formDataCount || 0
      const count =
        options.order === 'desc'
          ? Math.max(0, Number(middle) - Number(formCount))
          : Math.max(Number(middle) + Number(formCount))

      return {
        ...options,
        count
      }
    },
    scrollShow() {
      // 浮层表单时看show状态
      return this.isFloat && this.context !== 'editor' ? this.show : true
    },
    formItemCount() {
      const len = this.formItems.length || 0
      return len
    }
  },
  watch: {
    'data.form.id'() {
      this.refresh()
    },
    // 更新样式
    'data.style.inputStyle'() {
      this.update()
    },
    'data.style.color.text'(val) {
      this.changeText(val)
    },
    'data.style.color.inputBg'(val) {
      this.changeInputBg(val)
    },
    'data.style.color.inputBorder'(val) {
      this.changeInputBorder(val)
    },
    'data.style.color.inputPlaceholder'(val) {
      this.changeInputPlaceholder(val)
    }
  },
  created() {
    this.refresh()
    this.listenClickOfLocationButton()
  },
  mounted() {
    this.report()
    this.$emit('change')
    this.rootRef = this.$refs.form
  },
  methods: {
    jumpToHref(url) {
      /**
       * 兼容部分设备无法跳转
       * */
      if (url) {
        document.location.href = url
      }
      if (url && !isIOS) {
        setTimeout(() => {
          document.location.href = url
        }, 0)
      }
    },
    autoFillName(name) {
      this.formItems = this.formItems.map((item) => {
        if (item.sub_type === 1) {
          this.onFocusReportCvr(item, true)
          return { ...item, value: name }
        } else {
          return item
        }
      })
    },
    onFocusReportCvr(item, isAuto) {
      const id = item.id
      const eventMap = {
        input: MINI_list_input_cvr,
        address: MINI_list_address_cvr,
        idcard: MINI_list_idcard_cvr,
        telephone: MINI_list_phone_cvr,
        checkbox: MINI_list_checkbox_cvr,
        radio: MINI_list_radio_cvr,
        dropdown: MINI_list_dropdown_cvr,
        lbs_dropdown: MINI_lbs_dropdown_click_cvr
      }
      const event = eventMap[item.type]
      const cvrId = `${id}_cvr`
      if (id && !this[cvrId]) {
        event &&
          this.$reportUI(event, {
            ...this.reportInfo,
            label_name: item.label,
            form_item_count_cvr: this.formItemCount,
            is_auto_fill: isAuto ? 1 : 0
          })
        this[cvrId] = true
      }
    },

    autoFillPhone(item) {
      this.onFocusReportCvr(item, true)
    },
    confirm() {
      this.$reportUI(MINI_list_form_submit_click, {
        ...getFormButtonExpInfo()
      })
      return this.validate()
        .catch(this.scrollToFirstError)
        .then(() => {
          if (this.hasSubmitted) {
            this.$toast('您已提交表单，无需重复提交')
            return Promise.reject(new Error())
          } else {
            return Promise.resolve()
          }
        })
        .then(this.validatePhone)
        .then(this.submit)
        .catch(() => {})
    },
    validatePhone() {
      return new Promise((resolve, reject) => {
        if (this.$refs.telephone) {
          const isValid = this.$refs.telephone[0].validate()
          return isValid ? resolve() : reject(new Error())
        }
        return resolve()
      })
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.formItems.forEach((item) => this.validateField(item))

        const { error = '' } = this.formItems.find((item) => item.error) || {}

        return error ? reject(new Error()) : resolve()
      })
    },
    validateField(item) {
      console.log('item', item)
      const {
        type,
        value = '',
        is_allow_empty: isAllowEmpty,
        is_submit_validate: isSubmitValidate,
        is_detail_address: isDetailAddress = '',
        address = {}
      } = item || {}

      if (type === 'idcard') {
        if (!isAllowEmpty && !value) {
          item.error = '此项不能为空'
          return
        }
        if (isSubmitValidate && value) {
          const pass = IdentityCodeValid(value)
          item.error = pass ? '' : '请输入正确身份证号码'
          return
        }
      }

      if (isAllowEmpty) {
        item.error = ''
        return
      }

      if (type === 'address') {
        if (!address || !address.province || !address.province.name) {
          item.error = '此项不能为空'
          return
        }

        if (isDetailAddress && (!address || !address.detail)) {
          item.error = '请输入详细地址'
          return
        }

        item.error = ''
        return
      }

      if ([types.DROPDOWN, types.LBS, types.CUSTOMIZE_LBS_DROP_DOWN].includes(type)) {
        if (value.split(',').length !== item.levels) {
          item.error = '请选择下拉选项'
          return
        }
      }

      if (!value || !value.length) {
        item.error = '此项不能为空'
        return
      }

      if (type === 'telephone') {
        const normalReg = /^1[3-9]\d{9}$/
        const localPhoneReg = /^1[3-9][0-9*]{9}$/ // 带有****加密
        if ([phoneChannelEnum.LOCAL_PHONE, phoneChannelEnum.B_PHONE].includes(item.phone_channel)) {
          if (!localPhoneReg.test(value)) {
            item.error = '请输入正确的手机号'
            return
          }
        } else {
          if (!normalReg.test(value)) {
            item.error = '请输入正确的手机号'
            return
          }
        }
      }
      item.error = ''
    },
    validateAge() {
      const idCardItem = this.formItems.find((item) => item.type === 'idcard')
      if (!idCardItem) return true

      const {
        range_left: minAge,
        range_right: maxAge,
        value: idCardStr,
        is_allow_empty: isAllowEmpty
      } = idCardItem

      if (isAllowEmpty) return true
      const cardAge = getCardAge(idCardStr)

      if (minAge > 0 && cardAge < minAge) {
        this.$dialog.alert({
          message: `抱歉，未满${minAge}周岁及以上，无法提交该表单`,
          className: 'max-index'
        })
        return false
      } else if (maxAge > 0 && cardAge > maxAge) {
        this.$dialog.alert({
          message: `抱歉，超过${maxAge}周岁及以上，无法提交该表单`,
          className: 'max-index'
        })
        return false
      }
      return true
    },
    scrollToFirstError() {
      const formEle = this.$refs.form
      if (!formEle) return Promise.reject(new Error())

      const firstError = this.formItems.find((item) => item.error)
      const errorIdx = this.formItems.indexOf(firstError)
      const locationEle = formEle.getElementsByClassName('form-item')[errorIdx]

      if (this.container) {
        this.container.scrollTo({ top: getOffsetTop(locationEle, this.container) })
      } else {
        window.scrollTo({ top: getOffsetTop(locationEle) })
      }
      return Promise.reject(new Error())
    },
    submit() {
      if (this.submitLock) return
      if (!this.validateAge()) return

      this.submitLock = true
      const formData = serializeForm(this.form.id, this.formItems, this.formData)

      this.loading = true
      const reportInfo = {
        item_id: this.compId,
        comp_type: this.isFromRefer ? 'page_form' : 'form',
        is_float: +this.isFloat,
        ...getFormButtonExpInfo()
      }

      let showAgreementConfirm = false

      let url = `v1/form_data/page/${this.pageId}${window.location.search}`
      if (window?._globalData?.autoCreateAccPage === true) {
        if (!inBiliApp) {
          this.$toast('请在B站APP内提交表单')
          return
        }
        if (!window._loginMid) {
          this.$toast('请登录后再提交表单')
          return
        }
        const searchQuery = window.location.search
          ? `${window.location.search}&report_source=5`
          : '?report_source=5'
        //  `v1/form_data/personal_mgk/${this.pageId}${searchQuery}` 自然流量自动开通接口
        url = `v1/form_data/page/${this.pageId}${searchQuery}`

        if (this.accDisableData.blockType) {
          this.loading = false
          if (this.accDisableData.errorText) {
            this.$toast(this.accDisableData.errorText)
          }
          if (this.accDisableData.errorJumpUrl) {
            this.jumpToHref(this.accDisableData.errorJumpUrl)
          }
          return
        }
      }
      this.$request
        .post(url, formData, {
          useErrData: true
        })
        .then((res) => {
          if (res.code !== 0) return Promise.reject(res)

          this.hasSubmitted = true
          this.$reportUI(MINI_list_success, reportInfo)
          try {
            if (this.$refs.telephone) {
              const telRef = this.$refs.telephone[0]
              // 个人隐私保护
              telRef.handleAllowHistory(() => {
                this.$refs.modalConfirm.open()
                showAgreementConfirm = true
              })
              telRef.reportEqualTel()
            }
          } catch (err) {
            console.log(err)
          }
          // 不弹出确认弹窗时直接跳转，否则在弹窗选择后跳转
          if (!showAgreementConfirm) {
            this.$emit('submit')
          }
        })
        .catch((error) => {
          const message = (error && error.message) || error
          // 运营商手机号信息获取失败
          if (error.code === -3001001) {
            // 验证失败后重置token
            if (this.$refs.telephone) {
              this.$refs.telephone[0].reQueryLocalPhoneToken()
            }
            this.$reportWarn(WARN_page_form_phone_fail, { err_reason: 0 })
          }

          this.$dialog.alert({
            message,
            className: 'max-index'
          })
          this.$reportUI(MINI_list_fail, reportInfo)
          this.$reportWarn(WARN_page_form_submit_fail, reportInfo)
          if (message === 'RISK_TAG') {
            // 经营号黑产
            this.jumpToHref('https://gaoneng.bilibili.com/tetris/page/?pageId=976506011123765248')
          }
        })
        .finally(() => {
          this.submitLock = false
        })
    },
    query(formId) {
      if (!formId) {
        return Promise.reject(new Error())
      }

      return this.$request.get(`v1/form/${formId}`).then((res) => {
        return res.data
      })
    },
    getHistory() {
      if (!window.alita) return

      this.$request.post('v1/form_data/phone', serializePhone()).then(({ data }) => {
        this.history = data
        if (data.number) {
          this.$reportUI(MINI_get_form_history, {
            ...this.reportInfo
          })
        } else {
          this.$reportUI(MINI_no_get_form_history, {
            ...this.reportInfo
          })
        }
        // 已勾选允许历史手机号，且不存在历史手机号则直接获取b站手机号
        // 否则滑动触发弹窗逻辑
        if (!data.number && data.allow_history === allowHistoryStatusEnum.ALLOW) {
          this.queryBPhoneHandler()
        } else {
          this.scrollToQueryBPhone()
        }
      })
    },
    scrollToQueryBPhone() {
      // 排除掉弹窗层级的滑动，如协议书
      const appEle = document.querySelector('#app')
      appEle && appEle.addEventListener('touchmove', this.queryBPhoneHandler)
    },
    queryAreaList() {
      const hasAddress = this.formData.items.find((item) => item.type === types.ADDRESS)
      if (!hasAddress) return
      this.$request
        .get(
          'v1/landing_pages/areas',
          {},
          {
            baseURL: baseOpenURL
          }
        )
        .then(({ data }) => {
          this.areaList = data
        })
    },
    // 经营助手禁用名单获取
    getDisableUser() {
      if (window?._globalData?.autoCreateAccPage === true) {
        console.log('autoCreateAccPage', window?._globalData?.autoCreateAccPage)

        this.$request
          .get(
            `v1/comment/component/black/rule`,
            {},
            {
              baseURL: basePersonalURL
            }
          )
          .then((res) => {
            const { code = 500, message = '', data = {} } = res || {}
            const msg = message || '获取黑名单失败'
            console.log(msg)
            if (+code === 0) {
              let { blockType = '', errorText = '', errorJumpUrl } = data || {}
              this.accDisableData.blockType = blockType
              this.accDisableData.errorText = errorText
              this.accDisableData.errorJumpUrl = errorJumpUrl
              return
            }
          })
          .catch((e) => {
            console.log('e', e)
          })
      }
    },
    refresh() {
      const formId = this.data?.form?.id

      this.formError = false

      const formPromise = this.formInfo ? Promise.resolve(this.formInfo) : this.query(formId)
      formPromise
        .then((form) => {
          this.formData = form
          // 自动填充实验
          if (window._globalExp?.form_default?.form_ids?.includes(this.formData.id)) {
            this.formData.items.forEach((item) => {
              const index = window._globalExp.form_default.defaults.findIndex(
                (i) => item.id === i.item_id
              )
              if (index !== -1) {
                const values = window._globalExp.form_default.defaults[index]?.value || []
                const key = Math.floor(Math.random() * values.length)
                if (key === values.length) {
                  item.item_default = values[key - 1]
                } else {
                  item.item_default = values[key]
                }
              }
            })
          }
          // 判断是否为吉利表单
          window.__CUTOMIZE__ = form.customize || ''
          this.formDataCount = form.record_count
          this.loaded = true
          this.$emit('form-loaded')
          this.getDisableUser()
          this.getHistory()
          this.queryAreaList()
          this.update()
          this.action?.getFirstFormData && this.action.getFirstFormData(formId, form)

          if (this.context !== 'editor') {
            this.$nextTick(() => {
              this.$reportUI(MINI_form_loading_success, {
                ...this.reportInfo,
                ...getFormButtonExpInfo(),
                ...this.analyzeForm(form)
              })
              // 非浮层表单时
              if (!this.formInfo) {
                if (window._globalData?.formCount > 1) {
                  window._globalData.formCount--
                } else if (window._globalData?.formCount === 1) {
                  this.$reportUI(MINI_page_rendered, {
                    ...window._globalExp,
                    ...getFormButtonExpInfo()
                  })
                  // 置底按钮延迟上报
                  if (window._globalExp.is_add_button === 1) {
                    this.$reportUI(MINI_experiment_add_form_button, {
                      ...getFormButtonExpInfo()
                    })
                  }
                }
              }
            })
          }
        })
        .catch((e) => {
          console.log(e)
          this.formError = true
          this.formData = {}
          this.$reportWarn(WARN_page_get_form_data_fail, { reason: e })
          this.update()
          this.$emit('form-loaded')
        })
    },
    update() {
      const form = this.formData || {}
      const { inputStyle = 1 } = this.data?.style || {}
      const items = (form?.items || []).map((item) => formatFormItem(item, inputStyle))
      const formItems = items.filter((item) => item.type !== 'submit')
      const formButton = items.find((item) => item.type === 'submit') || {}
      this.formItems = formItems
      this.$set(this.formButton, 'label', formButton.label)
      this.$emit('submit-text', formButton.label, form.name)

      const telInfo = this.formItems.find((item) => item.type === 'telephone')
      if (telInfo) {
        this.telCompInfo = Object.assign(this.telCompInfo, telInfo)
        this.hasTelephone = true
      } else {
        this.telCompInfo = {
          autoFill: false,
          useLocalPhone: false
        }
      }

      this.$emit('change')
      this.$nextTick(() => {
        this.handleStyle()
      })
      // 默认埋点上报
      this.formItems.forEach((item) => {
        if (item.type === 'radio' && item.value !== undefined && item.value !== '') {
          this.onFocusReportCvr(item, true)
        }
        if (item.type === 'checkbox' && item.value && item.value?.length > 0) {
          this.onFocusReportCvr(item, true)
        }
      })
    },
    analyzeForm(form) {
      const { items = [] } = form || {}
      const map = {
        a_items: 0
      }
      items.forEach((i) => {
        const type = i.type
        const key = `a_${type}`
        if (type !== types.SUBMIT) {
          map.a_items++
          if (map[key]) {
            map[key]++
          } else {
            map[key] = 1
          }
        }
      })
      return map
    },
    report() {
      if (!this.isFromRefer) return
      this.$reportUI(MINI_list_pv, {
        ...this.reportInfo
      })
    },
    onFocusReport(item) {
      // if (!this.MINI_list_input) {
      //   this.$reportUI(MINI_list_input, {
      //     ...this.reportInfo,
      //     label_name: item.label
      //   })
      //   this.MINI_list_input = true
      // }
      const id = item.id

      const eventMap = {
        input: MINI_list_input,
        address: MINI_list_address,
        idcard: MINI_list_idcard,
        telephone: MINI_list_phone,
        checkbox: MINI_list_checkbox,
        radio: MINI_list_radio,
        dropdown: MINI_list_dropdown,
        lbs_dropdown: MINI_lbs_dropdown_click
      }
      const event = eventMap[item.type]
      if (id && !this[id]) {
        event &&
          this.$reportUI(event, {
            ...this.reportInfo,
            label_name: item.label
          })
        this[id] = true
      }
      this.onFocusReportCvr(item)
      if (item.type === 'input' || item.type === 'telephone') {
        this.$emit('form-focus')
      }
    },
    styleHandler(target = {}, style = {}) {
      const { classes, tags } = target
      if (this.rootRef) {
        let children = []
        if (classes) {
          classes.forEach((c) => {
            const ele = this.rootRef.getElementsByClassName(c)
            children = children.concat(...ele)
          })
        }
        if (tags) {
          tags.forEach((c) => {
            const ele = this.rootRef.getElementsByTagName(c)
            children = children.concat(...ele)
          })
        }
        children.forEach((i) => {
          Object.keys(style).forEach((s) => {
            i.style[s] = style[s]
          })
        })
      }
    },
    changeText(val) {
      const classes = [
        'cc-form-title', // 标题
        'form-item-label', // 表单标题
        'form-item-value', // 表单项
        'van-radio__label', // 单选
        'van-checkbox__label', // 多选
        'tel-pre', // 电话前缀
        'cc-form-input-wrap', // 输入文字
        'address-select', // 选中地址
        'dropdown-select' // 下拉框
      ]
      this.styleHandler({ classes }, { color: val })
    },
    changeInputBg(val) {
      let color = val
      if (this.data.style?.inputStyle === 2) {
        color = 'transparent'
      }
      const classes = [
        'cc-form-input-wrap', // 输入框
        'address-content', // 地址
        'dropdown-select' // 下拉框
      ]
      this.styleHandler({ classes }, { backgroundColor: color })
    },
    changeInputPlaceholder(val) {
      // 加上random 防止覆盖
      const CLASSNAME = 'form-placeholder-inject-css' + Math.random(0, 1).toString().slice(2, 12)
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = `
        .${CLASSNAME}::-webkit-input-placeholder { color: ${val} !important; }
        .${CLASSNAME}::-moz-placeholder { color: ${val} !important; }
        .${CLASSNAME}::-ms-input-placeholder { color: ${val} !important; }
      `

      document.getElementsByTagName('head')[0].appendChild(style)
      const inputEleArr = this.rootRef.getElementsByTagName('input')
      // console.log('inputEleArr', inputEleArr)
      // 此处无法执行报错 伪装
      Array.from(inputEleArr).forEach((input) => {
        const oldClass = input.className
        input.className = `${oldClass} ${CLASSNAME}`
      })
    },
    changeInputBorder(val) {
      const classes = [
        'cc-form-input-wrap', // 输入框
        'address', // 地址栏
        'tel-pre', // 电话前缀
        'tel-send-btn', // 验证码
        'dropdown-select' // 下拉框
      ]
      const fakeBorderClasses = [
        'arrow-down' // 下拉框箭头
      ]
      this.styleHandler({ classes }, { borderColor: val })
      this.styleHandler({ classes: fakeBorderClasses }, { color: val })
    },
    handleStyle() {
      const { text, inputBg, inputBorder, inputPlaceholder } = this.formColor
      this.changeText(text)
      this.changeInputBg(inputBg)
      this.changeInputBorder(inputBorder)
      this.changeInputPlaceholder(inputPlaceholder)
    },
    setPhoneNumber() {
      this.$refs.telephone &&
        this.bPhoneInfo?.hide_tel &&
        this.$refs.telephone[0].setNumber(this.bPhoneInfo?.hide_tel, phoneChannelEnum.B_PHONE)
    },
    queryBPhoneHandler() {
      if (window._globalExp?.b_login_phone !== bPhoneExpEnum.EXP_AUTH) return
      // 浮层表单不适用于该实验
      if (this.isFloat) return
      if (this.hasFetchedBPhone) return
      if (this.context !== 'editor' && this.loaded && this.hasTelephone && !window.authOpened) {
        // 未登录时不获取
        if (!window.alita.appInfo?.mid) return
        this.hasFetchedBPhone = true
        // 获取B站手机号接口
        this.getBPhone().then((res) => {
          this.bPhoneInfo = res
          // 返回异常 或 没有绑定b站手机号
          if (!res || !res?.hide_tel) return

          const show = () => {
            this.$refs && this.$refs.auth.open()
            // PV全局只弹一次
            window.authOpened = true
          }
          const {
            allow_login_info_toast: isToastShow,
            allow_history: allowHistory,
            number
          } = this.history || {}

          // 已勾选历史手机号且无历史手机号，不弹窗，直接预填写手机号
          if (allowHistory === allowHistoryStatusEnum.ALLOW) {
            if (!number) {
              this.setPhoneNumber()
            }
            // 其他情况，当弹窗过了48小时后，再弹窗
          } else if (!isToastShow) {
            show()
          }
        })
      }
    },
    getBPhone() {
      return this.$request
        .post('v1/form_data/user/info', serializeBPhone())
        .then((res) => {
          res?.data?.hide_tel
            ? this.$reportUI(MINI_login_phone_success)
            : this.$reportUI(MINI_login_phone_fail, { reason: 1 })
          return res.data
        })
        .catch(() => {
          this.$reportUI(MINI_login_phone_fail, { reason: 0 })
        })
    },
    postPhoneData(params = {}) {
      return this.$request.put(
        'v1/form_data/phone',
        serializePhoneExt({
          user_info: { ...params }
        })
      )
    },
    onAuthConfirm() {
      this.postPhoneData({ allow_history: allowHistoryStatusEnum.ALLOW })
      this.fillHistoryCheckbox()
      // 勾选手机号会带入历史信息，所以后置set
      this.$nextTick(() => {
        this.setPhoneNumber()
      })
      this.$reportUI(MINI_login_phone_modal_yes)
      this.closeAuth()
    },
    onAuthRefuse() {
      this.postPhoneData({ allow_history: allowHistoryStatusEnum.NOT_ALLOW })
      this.$reportUI(MINI_login_phone_modal_no)
      this.closeAuth()
    },
    onAuthOpen() {
      this.$reportUI(MINI_login_phone_modal_show)
      this.postPhoneData({ allow_login_info_toast: allowLoginInfoToastEnum.SHOW })
    },
    onAuthRead() {
      // this.closeAuth()
    },
    onAuthClickOverlay() {
      this.$reportUI(MINI_login_phone_modal_overlay)
    },
    closeAuth() {
      this.$refs.auth && this.$refs.auth.close()
    },
    fillHistoryCheckbox() {
      // 自动勾选历史手机号
      this.telCompInfo.autoFill = true
    },
    listenClickOfLocationButton() {
      window.addEventListener('click', () => {
        this.$nextTick(() => {
          if (window.hasClickFormLocationButton) {
            this.queryBPhoneHandler()
          }
        })
      })
    }
  }
}
</script>
<style lang="less">
.cc-form {
  box-sizing: border-box;
  width: 100%;
  padding: 18px 0;
  background-size: cover;
  background-repeat: no-repeat;
  &.no-padding-bottom {
    padding-bottom: 0;
  }
  .cc-form-padding {
    box-sizing: border-box;
    padding: 0 15px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .cc-form-header {
    margin-bottom: 20px;
    text-align: center;
    .cc-form-title {
      height: 27px;
      // line-height: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }
  .form-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    &.required .form-item-label:after {
      content: '*';
      display: inline-block;
      margin-left: 2px;
      font-weight: normal;
      color: #f56c6c;
    }
    &.form-item-btn {
      padding-top: 6px;
      margin-bottom: 0 !important;
    }
    &.padding-no {
      padding-top: 0;
    }
    &.form-item-error {
      position: relative;
      .form-item-error-msg {
        position: absolute;
        left: 0;
        bottom: -22px;
        width: 100%;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        color: #fe5656;
      }
      .cc-form-input-wrap,
      .address-wrap .address {
        border-color: #fe5656 !important;
      }
      .dropdown-select {
        border-color: #fe5656 !important;
      }
    }
  }
  /* .form-item + .form-item {
    margin-top: 24px
  } */
  .form-item-label {
    width: 100%;
    margin-bottom: 9px;
    line-height: 22px;
    color: #333333;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
  }
  .form-item-value {
    width: 100%;
    .van-radio + .van-radio,
    .van-checkbox + .van-checkbox {
      margin-top: 17px;
    }
    .van-checkbox__icon,
    .van-radio__icon {
      width: 15px;
      height: 15px;
      font-size: 15px;
      .van-icon {
        border: 1px solid #e6e6e6;
      }
    }
    .van-checkbox__icon .van-icon {
      border-radius: 4px;
    }
    .van-radio__label,
    .van-checkbox__label {
      color: #666;
      font-size: 14px;
    }
  }
  // 输入框有边框
  // &.form-input-style-1 {
  // }
  // 输入框仅底部有边框
  &.form-input-style-2 {
    .cc-form-input-wrap {
      border-width: 0 0 1px 0;
      border-radius: 0;
      padding-left: 0;
    }
    .form-item.telephone {
      .tel-num {
        padding: 10px 0;
      }
      .tel-pre {
        margin: 0 12px 0 0;
        position: relative;
        text-align: left;
        padding: 0 20px 0 0;
        height: 20px;
        line-height: 20px;
      }
      .tel-num .cc-form-input {
        padding-left: 20px;
      }
      .tel-send-btn {
        flex: 0 0 116px;
        padding: 0;
        border-left: none;
        text-align: right;
      }
    }
    .address-wrap .address {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }
    .dropdown-wrap .dropdown-select {
      border-width: 0 0 1px 0;
      border-radius: 0;
    }
  }
  // 输入框无标题
  &.form-input-style-3 {
    .form-item.input,
    .form-item.text,
    .form-item.telephone,
    .form-item.address,
    .form-item.dropdown,
    .form-item.lbs_dropdown,
    .form-item.idcard {
      .form-item-label {
        display: none;
      }
    }
  }
  // 输入框标题居左
  &.form-input-style-4 {
    .form-item.input,
    .form-item.text,
    .form-item.telephone,
    .form-item.address,
    .form-item.idcard {
      display: flex;
      .form-item-label {
        flex: 0 0 58px;
        display: -webkit-box;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }
      .form-item-value {
        flex: 1;
      }
    }
  }
  .submit-btn {
    width: 100%;
  }
}
.cc-form .form-item.required .form-item-label:after {
  width: 15px;
}
.wrap-outer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .wrap-inner {
    width: calc((100% - 15px) / 2);
    &:nth-child(odd) {
      margin-right: 15px;
    }
    &:nth-child(2) {
      margin-top: 0 !important;
    }
  }
  .van-checkbox__label,
  .van-radio__label {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
  }
}
// 浮层表单上的弹窗层级调到最大
.max-index {
  z-index: 100000 !important;
}
</style>
<style lang="less" scoped>
/deep/ .tel-help {
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
  .tel-help-checkbox {
    display: inline-block !important;
    vertical-align: middle;
  }
  .tel-help-msg {
    display: inline-block;
    vertical-align: -1px;
    line-height: 19px;
    margin-left: 4px;
    font-size: 12px;
    color: #999;
  }
  .tel-link {
    text-decoration: none;
    color: #0a8afa;
    cursor: pointer;
  }
  .van-checkbox__icon,
  .van-radio__icon {
    width: 15px;
    height: 15px;
    font-size: 15px;
    .van-icon {
      border: 1px solid #e6e6e6;
    }
  }
  .van-checkbox__icon .van-icon {
    border-radius: 4px;
  }
}
.template-m {
  padding: 12px 15px;
  background-color: #f6f7f8;
  .text {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    color: #999999;
  }
  .agreement {
    color: #0a8afa !important;
    cursor: pointer;
  }
}
</style>
